import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { AuthService } from '@services/auth-services/auth.service';
import { Database, onValue, ref, update } from '@angular/fire/database';

const KEY = 'user_settings';

@Injectable({ providedIn: 'root' })
export class UserSettingsService {
  private settings = new BehaviorSubject<Map<string, any>>(new Map<string, any>());

  constructor(
    private authService: AuthService,
    private database: Database
    // private firestore: Firestore
  ) {}

  getSettings(): Observable<any> {
    return this.settings.asObservable();
  }

  getSettingsSnapshot() {
    return this.settings.getValue();
  }

  getSettingsEntry(key: string): Observable<any> {
    return this.getSettings().pipe(
      map(settingsMap => {
        return settingsMap?.get(key);
      })
    );
  }

  saveSettingsEntry(key: string, value: any) {
    const settings = this.getSettingsSnapshot();
    settings.set(key, value);
    const entries = Object.fromEntries(settings);
    this.set(entries);
  }

  watch(): Observable<any> {
    const userId = this.getCurrentUserId();
    const path: string = `${KEY}/${userId}`;
    return new Observable(observer => {
      const dataRef = ref(this.database, path);
      return onValue(
        dataRef,
        snapshot => {
          observer.next(snapshot.val());
        },
        error => {
          observer.error(error);
        }
      );
    }).pipe(
      map(settings => {
        const settingsMap = new Map<string, any>(Object.entries(settings || {}));
        this.settings.next(settingsMap);
        return settings;
      })
    );
  }

  /**
   * Create or update user settings
   * @param settings
   */
  async set(settings: any): Promise<void> {
    const userId = this.getCurrentUserId();
    const path = `${KEY}/${userId}`;
    const dataRef = ref(this.database, path);
    await update(dataRef, settings);
  }

  /**
   * Gets the currently logged user
   * @private
   */
  private getCurrentUserId(): string | undefined {
    const user = this.authService.getCurrentUser();
    return user?.uid || user?.id;
  }
}
